import React from "react"
import { Link } from 'gatsby'
import tachyons from 'tachyons-components'
import Layout from '../components/Layout'
import GlobalStyle from "../styles/GlobalStyle"

const Wrapper = tachyons(`div`)`
pv3
`
const Section = tachyons(`section`)`
dt wrap pt5 pt6-ns pb5
`
const ButtonPrimary = tachyons(Link)`
button w-100 w-auto-l no-underline br2 ph4 pv3 dib mr3-ns bg-primary button-primary tc fw5 mt2
`
const ButtonSecondary = tachyons(Link)`
button w-100 w-auto-l no-underline br2 ph4 pv3 dib tc bg-secondary bs-secondary br-secondary mt3 
`

const NotFoundPage = () => (
  <Layout>
    <GlobalStyle />
    <Wrapper>
    <Section>
      <h1 className="f3 fw6 mb2">How did you end up here?</h1>
      <p className="lh-copy">Opsie, the page you're looking for isn't here anymore. Sorry about that <span role="img" aria-label="emoji">🙏🏻</span> If this page contains something important to you, please do <a without rel="noopener noreferrer" target="_blank" className="link" href="/about/">let me know here</a>.</p>
      <p className="mt4">
      <ButtonPrimary role="button" to="/about/">Let me know →</ButtonPrimary>
      <ButtonSecondary role="button" to="/blog/">Check out other articles →</ButtonSecondary>
      </p>
    </Section>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
